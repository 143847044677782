<template>
    <div class="ad-cont" v-if="showAd">
        <div ref="adcont" class="ad" id='div-gpt-ad-1732099959285-0'>
        </div>
        <van-icon :style="{
            'top':top+'px',
            'right':right+'px'
        }" class="close" name="cross" size="20" @click="show"/>
    </div>
</template>

<script>
export default {
    props:['id'],
    data() {
        return {
            right:0,
            top:0,
            showAd:true
        }
    },
    mounted() {
        /* eslint-disable */
        googletag = window.googletag;

        // googletag.cmd.push(function () {
        //     googletag.defineSlot('/23175089080/kennethgames.com_Interstitial_320x480_1', [320, 480], 'div-gpt-ad-1732099959285-0').addService(googletag.pubads());
        //     googletag.pubads().enableSingleRequest();
        //     googletag.enableServices();
        // });

        googletag.cmd.push(function () { googletag.display('div-gpt-ad-1732099959285-0'); });

        setTimeout(()=>{
            let p = this.$refs.adcont.getBoundingClientRect();
            this.right = p.left;
            this.top = p.top;
            let h = this.$refs.adcont.offsetHeight;
            console.log('55',h);

            if(!h){
                this.show();
            }
        },1000)
    },
    methods: {
        show(){
            this.showAd = false;
            if(this.id === this.$route.path){
                this.$router.go(0);
            }else{
                this.$router.push(this.id);
            }
            this.$emit('close',false)
        }
    },
}
</script>

<style lang="less" scoped>
.ad-cont{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    .ad{
        position: relative;
        left: 50%;
        top: 50%;
        min-width: 320px;
        width: 320px;
        background: #999;
        transform: translate(-50%,-50%);
    }
    .close{
        position: absolute;
    }
}
</style>