<template>
    <div class="ad-cont">
        <div class="ad" id='div-gpt-ad-1732099841915-0'></div>
    </div>
</template>

<script>
export default {
    mounted() {
        /* eslint-disable */
        googletag = window.googletag;

        // googletag.cmd.push(function() {
        //     googletag.defineSlot('/23175089080/kennethgames.com_300x250_3', [300, 250], 'div-gpt-ad-1732099841915-0').addService(googletag.pubads());
        //     googletag.pubads().enableSingleRequest();
        //     googletag.enableServices();
        // });

        googletag.cmd.push(function () { googletag.display('div-gpt-ad-1732099841915-0'); });

    }
}
</script>

<style lang="less" scoped>
.ad-cont{
    box-sizing: border-box;
    background: #F1F1F1;
    .ad{
        min-width: 300px;
        width: 300px;
        margin: 0 auto;
    }
}
</style>